import Button from 'components/shared/Button';
import routes from 'data/globals/routes';
import React from 'react';
import { ButtonsContainer, SubheaderParagraph } from './SubHeader.styled';

const SubHeader = ({ paragraph }) => (
   <>
      <SubheaderParagraph>{paragraph}</SubheaderParagraph>
      <ButtonsContainer>
         <Button link={routes.home}>Wróć na stronę główną</Button>
      </ButtonsContainer>
   </>
);

export default SubHeader;
